import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Button, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import CTA from "../sections/common/CTA";

import { device } from "../utils";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper darkTheme>
        <Section hero>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Box>
                  <Text variant="tag">Poster Projects</Text>
                  <Title className="my-4">
                    Infographic Poster -<br /> Stanley Hotel
                  </Title>
                </Box>
              </Col>
            </Row>

            <Box pt={["4rem", null, null, "6.25rem"]}>
              <img
                src="/projects/posters/stanley-hotel.jpg?nf_resize=fit&w=1140"
                alt=""
                className="img-fluid w-100"
              />
            </Box>
          </Container>
        </Section>
        <div className="mt-lg-3">
          <Container>
            <Row className="justify-content-center ">
              <Col lg="8">
                <Box>
                  <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                    For this infographic poster I wanted to take a look at
                    hauntings in the US and trends it sets throughout the year.
                    I expected it to rise leading up to Halloween and then dip
                    after that. I decided to focus on a specific “haunted”
                    location and I chose the Stanley Hotel made famous by Steven
                    King's The Shining. I thought it would be fitting since it
                    is located so close in Estes Park, CO and I had recently
                    watched the 2019 Doctor Sleep which follows a character from
                    the original film. My goals for this project are to show how
                    the popularity of the Stanley Hotel changes from month to
                    month.
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
          <div className="mt-lg-3 pt-5">
            <Container>
              <Row className="justify-content-center ">
                <Col lg="8" className="mb-4 mb-lg-0">
                  <Text variant="tag">Tools Used</Text>
                  <Title variant="cardBig" className="mt-3">
                    InDesign, Illustrator
                  </Title>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <Section bg="dark" className="pt-12">
          <Container>
            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="/revelations">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                `}
                >
                  Illustrated Short Story - Revelations
                </Button>
              </Link>
            </div>
          </Container>
        </Section>
        <Box py={4}>
          <CTA />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
